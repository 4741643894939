<template>
  <b-card-normal>
    <template v-slot:body>
      <b-alert variant="warning" class="text-center" show v-if="!k_no">
        <h4 class="alert-heading">Bilgi</h4>
        <div class="alert-body">Lütfen sol taraftan bir galeri seçiniz.</div>
      </b-alert>
      <div v-else>
        <b-row>
          <b-col>
            <input
              multiple
              type="file"
              ref="file"
              name="galeri"
              id="selectorImagesInput"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              class="selector-images-input"
              @change="handlerOnSumbited"
            />
            <div class="selector-images-button" :disabled="true" @click="handlerSelectedImages">
              <feather-icon icon="FolderPlusIcon" size="48" v-if="!show" />
              <b-spinner v-else />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <draggable v-model="data.gorseller" handle=".draggable-task-handle" @end="handlerResimSiraChange">
            <b-col md="4" v-for="(item, i) in data.gorseller" :key="i" class="py-1 draggable-task-handle">
              <b-img thumbnail fluid :src="imageFile(item.gorsel)" alt="" />
              <b-button
                variant="danger"
                class="btn-icon position-absolute"
                style="top: 25px; right: 25px"
                @click="handlerRemoveImages(item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </draggable>
        </b-row>
      </div>
    </template>
  </b-card-normal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardNormal from '@/@core/components/b-card-actions/BCardNormal.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import draggable from 'vuedraggable';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    VuePerfectScrollbar,
    BCardNormal,
    draggable,
  },
  props: {
    updateData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageFile() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

      return (img) => `${base_url}uploads/galeri/thumbnail/${img}`;
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.files = ref(null);
    expo.k_no = ref(null);
    expo.data = ref([]);
    expo.drag = ref(true);

    expo.scrollbarSettings = ref({
      maxScrollbarLength: 50,
      suppressScrollX: true,
    });
    expo.handlerSelectedImages = () => {
      const file = document.getElementById('selectorImagesInput');
      file.click();
    };
    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;
      Object.keys(forms).map(function (key) {
        formData.append('galeri', forms[key]);
      });

      formData.append('k_no', expo.k_no.value);

      formData.forEach((val) => {
        console.log(val);
      });

      await store
        .dispatch('galeriResimEkle', formData)
        .then((res) => {
          expo.show.value = false;
          expo.data.value = store.getters.getGaleriler.find((x) => x.k_no == expo.k_no.value);
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerRemoveImages = async (event) => {
      const data = { galeri_k_no: expo.k_no.value, ...event };
      await store.dispatch('galeriResimSil', data).then((res) => {
        if (res.data.success == true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Resim Silme Başarılı',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          expo.data.value = store.getters.getGaleriler.find((x) => x.k_no == expo.k_no.value);
        }
      });
    };

    expo.handlerResimSiraChange = () => {
      store
        .dispatch('galeriResimSiraGuncelle', { k_no: expo.k_no.value, gorseller: expo.data.value })
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Sıra güncellendi.',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.data.value = computed(() => store.getters.getGaleriler);

    watch(
      props,
      (val) => {
        if (val) {
          expo.k_no.value = val.updateData.k_no;
          expo.data.value = store.getters.getGaleriler.find((x) => x.k_no == expo.k_no.value);
        }
      },
      { deep: true }
    );
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.selector-images-input {
  display: none;
}
.selector-images-button {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  // max-width: 250px;
  height: auto;
  max-height: 100px;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  padding: 55px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: darken(#dee, 9);
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
